var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login-page" }, [
    _c("section", { staticClass: "p-relative z-2" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "col-6 md-down:col-12 bg-white content-center",
            staticStyle: { "min-height": "100vh" }
          },
          [
            _c("div", { staticClass: "text-center pt-10" }, [
              _c("img", {
                staticClass: "w-contain d-inline-block mb-2",
                staticStyle: { "max-width": "250px" },
                attrs: {
                  src: require("../../../assets/logo-word.svg"),
                  alt: ""
                }
              }),
              _vm._v(" "),
              _c("small", { staticClass: "text-muted d-block strong" }, [
                _vm._v(_vm._s(_vm.$t("dashboard")))
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "w-100 my-auto mx-auto py-5",
                staticStyle: { "max-width": "600px" }
              },
              [
                _c("mega-input", {
                  attrs: {
                    placeholder: "email@mail.ru",
                    label: "Email",
                    incorrect: !_vm.validEmail
                  },
                  model: {
                    value: _vm.email,
                    callback: function($$v) {
                      _vm.email = $$v
                    },
                    expression: "email"
                  }
                }),
                _vm._v(" "),
                _c("mega-input", {
                  attrs: {
                    type: "password",
                    placeholder: "••••••••",
                    label: _vm.$t("password"),
                    incorrect: !_vm.validPassword
                  },
                  on: { keyup: _vm.onKeyUp },
                  model: {
                    value: _vm.password,
                    callback: function($$v) {
                      _vm.password = $$v
                    },
                    expression: "password"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "btn px-4 bg-white",
                        attrs: { to: { name: "Recovery" } }
                      },
                      [_vm._v(_vm._s(_vm.$t("forgot_pwd")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "mega-button",
                      {
                        staticClass: "px-4 btn-primary ml-auto",
                        attrs: { loading: _vm.loading },
                        on: { click: _vm.submit }
                      },
                      [_vm._v(_vm._s(_vm.$t("login")))]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "text-center pb-4" }, [
              _vm.language === "en"
                ? _c(
                    "span",
                    {
                      staticClass: "text-muted",
                      on: { click: _vm.switchLang }
                    },
                    [_vm._v("Русский")]
                  )
                : _c(
                    "span",
                    {
                      staticClass: "text-muted",
                      on: { click: _vm.switchLang }
                    },
                    [_vm._v("English")]
                  )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col p-relative md-down:d-none" },
          [_c("bg-particles", { attrs: { count: 60 } })],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center pb-1" }, [
      _c("small", { staticClass: "text-muted" }, [
        _vm._v("ARROUND Dashboard v 2.5.0")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }