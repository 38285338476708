<template>
  <div class="login-page">
    <section class="p-relative z-2">
      <div class="row">
        <div
          class="col-6 md-down:col-12 bg-white content-center"
          style="min-height: 100vh"
        >
          <div class="text-center pt-10">
            <img
              :src="require('../../../assets/logo-word.svg')"
              class="w-contain d-inline-block mb-2"
              alt=""
              style="max-width: 250px;"
            />
            <small class="text-muted d-block strong">{{
              $t("dashboard")
            }}</small>
          </div>

          <div class="w-100 my-auto mx-auto py-5" style="max-width: 600px;">
            <mega-input
              placeholder="email@mail.ru"
              label="Email"
              v-model="email"
              :incorrect="!validEmail"
            />
            <mega-input
              type="password"
              placeholder="••••••••"
              :label="$t('password')"
              v-model="password"
              @keyup="onKeyUp"
              :incorrect="!validPassword"
            />

            <div class="d-flex">
              <router-link
                :to="{ name: 'Recovery' }"
                class="btn px-4 bg-white"
                >{{ $t("forgot_pwd") }}</router-link
              >
              <mega-button
                class="px-4 btn-primary ml-auto"
                :loading="loading"
                @click="submit"
                >{{ $t("login") }}</mega-button
              >
            </div>
          </div>

          <div class="text-center pb-1">
            <small class="text-muted">ARROUND Dashboard v 2.5.0</small>
          </div>

          <div class="text-center pb-4">
            <span
              class="text-muted"
              @click="switchLang"
              v-if="language === 'en'"
              >Русский</span
            >
            <span class="text-muted" @click="switchLang" v-else>English</span>
          </div>
        </div>
        <div class="col p-relative md-down:d-none">
          <bg-particles :count="60" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BgParticles from "./components/bg-particles";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      remember: true,
      loading: false,
      redirect: this.$route.query.redirect || "/"
    };
  },
  computed: {
    validEmail() {
      let valid = true;
      let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/;

      this.$check(this.email).then(
        a => a.length > 1 && !mailFormat.test(a.toLowerCase()),
        () => (valid = false)
      );

      return valid;
    },
    validPassword() {
      let valid = true;

      this.$check(this.password).then(
        a => a && a.length < 3,
        () => (valid = false)
      );

      return valid;
    },
    language() {
      return this.$store.state.lang;
    }
  },
  beforeMount() {
    if (this.$store.state.token) this.$router.push("/");
  },
  methods: {
    onKeyUp(event) {
      if (event.key === "Enter") this.submit();
    },

    submit: function() {
      if (!this.email || !this.password) {
        this.$alert.info("Please enter email and password");
        return false;
      }

      if (!this.validEmail) {
        this.$alert.danger("Email is not valid");
        return false;
      }

      if (!this.validPassword) {
        this.$alert.danger("Password is not valid");
        return false;
      }

      this.loading = true;

      let data = {
        remember: this.remember,
        email: this.email,
        password: this.password
      };

      return this.$store
        .dispatch("login", data)
        .then(() => {
          window.location.href = "/";
        })
        .catch(() => {
          this.$alert.danger("Email or password is not correct");
          this.loading = false;
          this.password = "";
        });
    },
    switchLang() {
      this.$i18n.locale = this.$i18n.locale === "en" ? "ru" : "en";
      this.$store.dispatch("language", this.$i18n.locale);
    }
  },
  components: {
    BgParticles
  }
};
</script>
